// extracted by mini-css-extract-plugin
export var backgroundImage = "winter-contest-background-module--background-image--ed5ae";
export var backgroundImageLeft = "winter-contest-background-module--background-image-left--0d7c1";
export var backgroundImageRight = "winter-contest-background-module--background-image-right--04d54";
export var backgroundMain = "winter-contest-background-module--background-main--15543";
export var backgroundMainBorder = "winter-contest-background-module--background-main-border--347fa";
export var backgroundMiddle = "winter-contest-background-module--background-middle--74c54";
export var backgroundPadding = "winter-contest-background-module--background-padding--a4906";
export var backgroundSnow = "winter-contest-background-module--background-snow--74de3";
export var backgroundUnlimited = "winter-contest-background-module--background-unlimited--8974b";
export var errorBlink = "winter-contest-background-module--error-blink--eb1d2";