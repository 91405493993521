import React from 'react';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import { icon, title, text } from './winter-contest-winner-header.module.scss';

import WinnerIcon from '../../assets/images/svg/winner.svg';

interface IWinterContestWinnerHeader {
    className?: string;
}

const WinterContestWinnerHeader: React.FC<IWinterContestWinnerHeader> = ({ className }) => {
    const { t } = useI18next();

    return (
        <div className={className}>
            <WinnerIcon className={icon} />
            <h1 className={title}>{t('winter.contest.winner.title')}</h1>
            <p className={text}>{t('winter.contest.winner.description')}</p>
        </div>
    );
};

export default WinterContestWinnerHeader;
