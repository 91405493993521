import React from 'react';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import {
    contact,
    sectionTitle,
    elementsWrapper,
    element,
    title,
    content,
    link,
} from './winter-contest-contact.module.scss';

import staticFiles from '../../config/static-files';

interface IWinterContestContactProps {
    className: string;
}

const WinterContestContact: React.FC<IWinterContestContactProps> = ({ className }) => {
    const { t } = useI18next();

    return (
        <div className={`${className} ${contact}`}>
            <p className={sectionTitle}>{t('winter.contest.contact')}</p>
            <div className={elementsWrapper}>
                <div className={element}>
                    <p className={title}>{t('winter.contest.contact.email.label')}</p>
                    <p className={content}>{t('winter.contest.contact.email')}</p>
                </div>
                <div className={element}>
                    <p className={title}>{t('winter.contest.contact.regulations.label')}</p>
                    <p className={content}>
                        <span>{t('winter.contest.contact.regulations.1')}</span>
                        <a
                            className={link}
                            href={staticFiles.winterContestRegulations}
                            target={'_blank'}
                            rel="noreferrer"
                        >
                            {t('winter.contest.contact.regulations.2')}
                        </a>
                        <span>{t('winter.contest.contact.regulations.3')}</span>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default WinterContestContact;
