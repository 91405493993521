import axios from 'axios';
import prependApiUrl from '../utils/prepend-api-url';

import { IWinterForm } from '../models/winter-form.model';
import { IDefaultContestWinnerForm } from '../models/default-contest-form.model';

export const winterContestFormMutation = (data: IWinterForm) => {
    return axios.post(prependApiUrl('/taste-of-winter/create'), {
        ...data,
    });
};

export const postWinterContestWinner = (createForm: IDefaultContestWinnerForm) => {
    return axios.post(prependApiUrl('/taste-of-winter/addresses'), {
        ...createForm,
    });
};

export const getWinterContestCheckToken = (token: string) => {
    return axios.get(prependApiUrl(`/taste-of-winter/check/${token}`), {});
};
