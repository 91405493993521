import React from 'react';
import { graphql } from 'gatsby';
import {QueryClient, QueryClientProvider} from "react-query";

import {
    layout,
    background,
    header,
    form,
    contact,
} from './winter-contest-winner-page.module.scss';

import { IPage } from '../models/page.model';

import WinterContestBackground from '../components/organisms/winter-contest-background';
import WinterContestContact from '../components/molecules/winter-contest-contact';
import MainLayout from '../layouts/main-layout';
import WinterContestWinnerHeader from '../components/molecules/winter-contest-winner-header';
import WinterContestWinnerForm from '../components/organisms/winter-contest-winner-form';

interface IWinterContestWinnerPage {
    readonly data: {
        page: IPage | null;
    };
}

const WinterContestWinnerPage: React.FC<IWinterContestWinnerPage> = ({ data }) => {
    const { page } = data;
    const clientQuery = new QueryClient();

    return (
        <QueryClientProvider client={clientQuery}>
            <MainLayout className={layout} page={page}>
                <WinterContestBackground backgroundClassName={background} isSnowVisible={true} />
                <WinterContestWinnerHeader className={header} />
                <WinterContestWinnerForm className={form} />
                <WinterContestContact className={contact} />
            </MainLayout>
        </QueryClientProvider>

    );
};

export const query = graphql`
    query ($language: String!, $site: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }

        page: strapiPage(
            locale: { eq: $language }
            site: { eq: $site }
            isDummyContent: { eq: false }
        ) {
            ...pageFields
        }
    }
`;

export default WinterContestWinnerPage;
