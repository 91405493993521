import React, { useState } from 'react';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import { Form, Formik } from 'formik';
import { useMutation } from 'react-query';
import { getPathFromSlug } from '../../../plugins/gatsby-plugin-ap-i18next/src/get-path-from-slug';
import { navigate } from 'gatsby';

import {
    container,
    formField,
    innerWrapper,
    inputGrid,
    input,
    fullWidth,
    button,
    recaptchaBadge,
    calendarIcon,
    errorInfo,
} from './winter-contest-winner-form.module.scss';

import Calendar from '../../assets/images/svg/calendar.svg';

import pagesContext from '../../config/pages-context';
import useContestTokenCheck from '../../hooks/use-contest-token-check';
import { getWinterContestCheckToken, postWinterContestWinner } from '../../api-ssr/winter-contest';
import {
    getInitialWinterContestWinnerFormValues,
    getWinterContestWinnerFormSchema,
} from '../../formik/winter-contest-winner.config';

import Loader from '../atoms/loader';
import Button from '../atoms/button';
import FormField from '../atoms/form-field';
import FormInput from '../atoms/form-input';
import Captcha from '../atoms/captcha';

interface IWinterContestWinnerForm {
    className: string;
}

const WinterContestWinnerForm: React.FC<IWinterContestWinnerForm> = ({ className }) => {
    const { t, language } = useI18next();
    const [loading, setLoading] = useState(false);
    const [dateType, setDateType] = useState('text');
    const [submitClicked, setSubmitClicked] = useState(false);

    const onLoad = () => {
        setLoading(false);
    };

    const redirectPages = {
        default: pagesContext.winterContest.slug,
        expired: pagesContext.winterContestExpired.slug,
    };

    const token = useContestTokenCheck(language, getWinterContestCheckToken, redirectPages, onLoad);

    const mutation = useMutation(postWinterContestWinner, {
        onSuccess: () => {
            navigate(
                getPathFromSlug(pagesContext.winterContestThankYou.slug, language) +
                    `?from=winnerPage`
            );
        },
        onError: () => {
            alert(t('api.error'));
        },
    });

    const { isLoading } = mutation;

    return (
        <div className={`${container} ${className}`}>
            {loading && <Loader />}
            <Formik
                initialValues={getInitialWinterContestWinnerFormValues(token)}
                validationSchema={getWinterContestWinnerFormSchema(t)}
                onSubmit={(values, actions) => {
                    actions.setSubmitting(false);
                    mutation.mutate({ ...values });
                }}
            >
                {({ errors, touched }) => (
                    <Form className={innerWrapper}>
                        <div className={inputGrid}>
                            <FormField
                                id={'firstname'}
                                hintText={
                                    errors.firstname && touched.firstname ? errors.firstname : null
                                }
                                errorStyle={!!(errors.firstname && touched.firstname)}
                                className={formField}
                            >
                                <FormInput
                                    id={'firstname'}
                                    name={'firstname'}
                                    placeholder={t('winter.contest.winner.firstname')}
                                    errorStyle={!!(errors.firstname && touched.firstname)}
                                    className={input}
                                />
                            </FormField>
                            <FormField
                                id={'lastname'}
                                hintText={
                                    errors.lastname && touched.lastname ? errors.lastname : null
                                }
                                errorStyle={!!(errors.lastname && touched.lastname)}
                                className={formField}
                            >
                                <FormInput
                                    id={'lastname'}
                                    name={'lastname'}
                                    placeholder={t('winter.contest.winner.lastname')}
                                    errorStyle={!!(errors.lastname && touched.lastname)}
                                    className={input}
                                />
                            </FormField>
                            <FormField
                                id={'birthday'}
                                hintText={
                                    errors.birthday && touched.birthday ? errors.birthday : null
                                }
                                errorStyle={!!(errors.birthday && touched.birthday)}
                                className={formField}
                            >
                                <FormInput
                                    id={'birthday'}
                                    name={'birthday'}
                                    placeholder={t('winter.contest.winner.birthday')}
                                    errorStyle={!!(errors.birthday && touched.birthday)}
                                    className={input}
                                    onClick={() => {setDateType('date')}}
                                    type={dateType}
                                />
                                <Calendar className={calendarIcon} />
                            </FormField>
                            <FormField
                                id={'email'}
                                hintText={errors.email && touched.email ? errors.email : null}
                                errorStyle={!!(errors.email && touched.email)}
                                className={formField}
                            >
                                <FormInput
                                    id={'email'}
                                    name={'email'}
                                    placeholder={t('winter.contest.winner.email')}
                                    errorStyle={!!(errors.email && touched.email)}
                                    className={input}
                                />
                            </FormField>
                            <FormField
                                id={'phone'}
                                hintText={errors.phone && touched.phone ? errors.phone : null}
                                errorStyle={!!(errors.phone && touched.phone)}
                                className={formField}
                            >
                                <FormInput
                                    id={'phone'}
                                    name={'phone'}
                                    placeholder={t('winter.contest.winner.phone')}
                                    errorStyle={!!(errors.phone && touched.phone)}
                                    className={input}
                                />
                            </FormField>
                            <FormField
                                id={'street'}
                                hintText={errors.street && touched.street ? errors.street : null}
                                errorStyle={!!(errors.street && touched.street)}
                                className={formField}
                            >
                                <FormInput
                                    id={'street'}
                                    name={'street'}
                                    placeholder={t('winter.contest.winner.street')}
                                    errorStyle={!!(errors.street && touched.street)}
                                    className={input}
                                />
                            </FormField>
                            <FormField
                                id={'houseNumber'}
                                hintText={
                                    errors.houseNumber && touched.houseNumber
                                        ? errors.houseNumber
                                        : null
                                }
                                errorStyle={!!(errors.houseNumber && touched.houseNumber)}
                                className={formField}
                            >
                                <FormInput
                                    id={'houseNumber'}
                                    name={'houseNumber'}
                                    placeholder={t('winter.contest.winner.house')}
                                    errorStyle={!!(errors.houseNumber && touched.houseNumber)}
                                    className={input}
                                />
                            </FormField>
                            <FormField
                                id={'apartmentNumber'}
                                hintText={
                                    errors.apartmentNumber && touched.apartmentNumber
                                        ? errors.apartmentNumber
                                        : null
                                }
                                errorStyle={!!(errors.apartmentNumber && touched.apartmentNumber)}
                                className={formField}
                            >
                                <FormInput
                                    id={'apartmentNumber'}
                                    name={'apartmentNumber'}
                                    placeholder={t('winter.contest.winner.apartment')}
                                    errorStyle={
                                        !!(errors.apartmentNumber && touched.apartmentNumber)
                                    }
                                    className={input}
                                />
                            </FormField>
                            <FormField
                                id={'zipCode'}
                                hintText={errors.zipCode && touched.zipCode ? errors.zipCode : null}
                                errorStyle={!!(errors.zipCode && touched.zipCode)}
                                className={formField}
                            >
                                <FormInput
                                    id={'zipCode'}
                                    name={'zipCode'}
                                    placeholder={t('winter.contest.winner.zipcode')}
                                    errorStyle={!!(errors.zipCode && touched.zipCode)}
                                    className={input}
                                />
                            </FormField>
                            <FormField
                                id={'city'}
                                hintText={errors.city && touched.city ? errors.city : null}
                                errorStyle={!!(errors.city && touched.city)}
                                className={`${fullWidth} ${formField}`}
                            >
                                <FormInput
                                    id={'city'}
                                    name={'city'}
                                    placeholder={t('winter.contest.winner.city')}
                                    errorStyle={!!(errors.city && touched.city)}
                                    className={input}
                                />
                            </FormField>
                        </div>
                        <Button isLoading={isLoading} className={button} onClick={() => {setSubmitClicked(true)}}>
                            {t('winter.contest.winner.submit')}
                        </Button>
                        {process.env.RECAPTCHA && (
                            <Captcha className={recaptchaBadge} siteKey={process.env.RECAPTCHA} />
                        )}
                        {
                            Object.keys(errors).length !== 0 && submitClicked && <p className={errorInfo}>{t('winter.contest.form.error.info')}</p>
                        }
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default WinterContestWinnerForm;
