import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import {
    backgroundMain,
    backgroundMiddle,
    backgroundUnlimited,
    backgroundPadding,
    backgroundMainBorder,
    backgroundImage,
    backgroundImageLeft,
    backgroundImageRight,
    backgroundSnow,
} from './winter-contest-background.module.scss';

interface IWinterContestBackgroundProps {
    className?: string;
    isBubblesVisible?: boolean;
    isBorderVisible?: boolean;
    isSnowVisible?: boolean;
    backgroundClassName?: string;
    isWinnerThankYouView?: boolean;
    isExpiredPage?: boolean;
}

const fruitsLeftSrc = '../../assets/images/winter-contest/fruits_left.png';
const fruitsRightSrc = '../../assets/images/winter-contest/fruits_right.png';
const backgroundSrc = '../../assets/images/winter-contest/background.png';
const snowSrc = '../../assets/images/winter-contest/snow.png';

const WinterContestBackground: React.FC<IWinterContestBackgroundProps> = ({
    className = '',
    isBubblesVisible,
    isBorderVisible,
    isSnowVisible,
    backgroundClassName,
    isWinnerThankYouView,
    isExpiredPage,
}) => {
    return (
        <>
            {isBubblesVisible && (
                <StaticImage
                    className={`${className} ${backgroundMain} ${backgroundMiddle}
                    ${isBorderVisible ? backgroundMainBorder : ''} 
                    ${isWinnerThankYouView ? backgroundPadding : ''}
                    ${isExpiredPage ? backgroundUnlimited : ''}`}
                    objectFit={'cover'}
                    objectPosition={'center'}
                    src={backgroundSrc}
                    alt=""
                />
            )}
            {isSnowVisible && (
                <StaticImage
                    className={backgroundSnow}
                    objectFit={'cover'}
                    objectPosition={'center'}
                    src={snowSrc}
                    alt=""
                />
            )}
            <StaticImage
                className={`${backgroundImage} ${backgroundImageLeft} ${
                    backgroundClassName ? backgroundClassName : ''
                }`}
                objectFit={'cover'}
                objectPosition={'center'}
                src={fruitsLeftSrc}
                alt=""
            />
            <StaticImage
                className={`${backgroundImage} ${backgroundImageRight} ${
                    backgroundClassName ? backgroundClassName : ''
                }`}
                objectFit={'cover'}
                objectPosition={'center'}
                src={fruitsRightSrc}
                alt=""
            />
        </>
    );
};

export default WinterContestBackground;
